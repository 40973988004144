<template>
  <div class="about">
    <div class="about_m5 ">
      <div class="m">
        <p style="color: #fff">
          专注量化CTA策略，使用机器学习处理海量数据、回归分析建立交易模型、<br />分析期货市场交易走势，连续多年取得稳健收益
        </p>
      </div>
    </div>
    <div class="about_m6 animated" data-ani="fadeInUp">
      <div class="m">
        <div class="l">
          <p class="p1">公司简介<br /></p>
          <p class="p2" style="margin-bottom: 25px; margin-top: 10px">
            重庆大凡系西南地区以资产管理为主的综合性专业量化投资公司之一，于2010年初步组建，2014年正式成立，注册资金1千万,实缴资本1千万。
          </p>
          <p class="p3" style="margin-bottom: 50px">
            <a href="javascript:void(0)" @click="routerFun('/company')">查看更多</a>
          </p>
        </div>
        <div class="ms">
          <ul>
            <li>
              <div class="i"><img src="../assets/i22.png" /></div>
              <div class="r">
                <p class="p1">多次荣获行业相关奖项</p>
                <p class="p2">公司专注二级市场量化投资</p>
              </div>
            </li>
            <li>
              <div class="i"><img src="../assets/i23.png" /></div>
              <div class="r">
                <p class="p1">大凡理念</p>
                <p class="p2">大凡人遵循“因天下之力、</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="about_m7 animated" data-ani="fadeInUp">
      <div class="m">
        <div class="ms">
          <p class="p1">大凡团队</p>
          <p class="p2">
            信号识别、机器学习领域专家团队，汇聚了有着丰富经验的证券、银行、期货、基金<br />领域人才，具备完善的中后台支持体系，
            在同行业中显露
          </p>
          <p class="p3"><a href="javascript:void(0)" @click="routerFun('/company')">查看更多</a></p>
        </div>
      </div>
    </div>
    <div class="about_m8 animated" data-ani="fadeInUp">
      <div class="m">
        <div class="l">
          <div class="ms"  @click="routerFun('/news')">
            <p class="p1">大凡新闻</p>
            <ul>
              <li>
                <a href="javascript:void(0)"
                  ><span>·</span
                  >【大凡荣誉】大凡投资荣获第五届Wind最强私募榜单多项大奖</a
                >
              </li>
              <li>
                <a href="javascript:void(0)"
                  ><span>·</span>投资理财明白纸 读完十句话，明明白白投资</a
                >
              </li>
            </ul>
            <p class="p3">
              <a href="javascript:void(0)">查看更多</a>
            </p>
          </div>
        </div>

        <div class="r">
          <img src="../assets/8596df072cf74184cc1b9d923e36c958.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  components: {},
  name: "about",
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
    //  this.handleAnimate(); //初始化第一次加载时在视口内就执行动画
      addEventListener("scroll", this.handleScroll);
      addEventListener("scroll", this.handleAnimate);
    });
  },
  destroyed() {
    removeEventListener("scroll", this.handleScroll); //避免影响其他页面
    removeEventListener("scroll", this.handleAnimate);
  },
  methods: {  
    routerFun(url) {
        this.$router.push({
          path:url,
        })
    },
    handleScroll() {
      // 执行滚动到当前元素时想要执行的操作
      // console.log('Scrolled to .my-element');
    },

    handleAnimate() {
      let top =
        pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let vh = document.documentElement.clientHeight;
      let dom = document.querySelectorAll(".animated");
      [].slice.call(dom).forEach((v) => {
        if (top + vh > v.offsetTop) {
          var delay = v.dataset.delay;
          if (delay) {
            setTimeout(() => {
              v.style.opacity = 1;
              v.classList.add(v.dataset.ani);
            }, delay);
          } else {
            v.style.opacity = 1;
            v.classList.add(v.dataset.ani);
          }
        } else {
          v.classList.remove(v.dataset.ani);
          v.style.opacity = 0;
        }
      });
    },},
};
</script>
<style lang="scss" scoped>
@media (max-width: 975px) {
  .about {
    .about_m7 {
       .m{
        width:  100% !important;
        .ms{
          text-align: center !important; 
        }
       }
    }
    .about_m5{
      .m{
        width:  100% !important;
        box-sizing: border-box;
        padding-left: 10px;
      }
    }
    .about_m6 {
      width: 100% !important;
      .m{
        padding-left: 10px !important;
        //width: 100% !important;
        .l{
          flex:none !important;
          width: 440px !important; 
        }
      }
    }
    .about_m8 {
       .m{
        .l{
          box-sizing: border-box;
          padding-left: 20px;
        }
        // width:  100% !important;
        .ms{
   
        }
       }
    }
  }
}
.about {
  width: 100%;
  margin-top: -30px;
  overflow: hidden;
  .about_m8 {
    width: 100%;
    overflow: hidden;
    margin-top: 80px;
    margin-bottom: 80px;
    .m {
      width: 1200px;
      height: auto;
      margin: 0px auto;
      display: flex;
      .l {
        flex: 1;
        overflow: hidden;
        display: flex;
        ul {
          width: 80%;
          overflow: hidden;
          margin-top: 20px;
          li {
            overflow: hidden;
            font-size: 16px;
            line-height: 40px;
            height: 40px;
            text-overflow: ellipsis;
            white-space: nowrap;
            a {
              color: #333;
              span {
                font-size: 30px;
                font-weight: bold;
                position: relative;
                top: 5px;
                margin-right: 10px;
              }
            }
          }
        }
        .ms {
          overflow: hidden;
          align-self: center;
        }
        .p1 {
          font-size: 40px;
          color: #333;
        }
        .p3 {
          margin-top: 20px;
          a {
            display: inline-block;
            background: #fff;
            border: 1px solid #7d7d7d;
            font-size: 16px;
            color: #000;
            width: 140px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            border-radius: 10px;
            &:hover {
              background: #e60012;
              color: #fff;
            }
          }
        }
      }
      .r {
        width: 600px;
        height: 370px;
        display: flex;
        img {
          display: block;
          margin: 0px auto;
          max-width: 100%;
          max-height: 100%;
          align-self: center;
        }
      }
    }
  }
  .about_m7 {
    width: 100%;
    height: 540px;
    margin-top: 50px;
    background: url(../assets/i34.jpg) no-repeat center center;
    .m {
      width: 1200px;
      height: 100%;
      display: flex;
      .ms {
        width: 100%;
        overflow: hidden;
        align-self: center;
        text-align: right;
        .p1 {
          font-size: 40px;
          color: #333;
        }
        .p2 {
          font-size: 18px;
          color: #191919;
          line-height: 40px;
          margin-top: 25px;
        }
        .p3 {
          overflow: hidden;
          margin-top: 20px;
          a {
            display: inline-block;
            background: #fff;
            border: 1px solid #7d7d7d;
            font-size: 16px;
            color: #000;
            width: 140px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            border-radius: 10px;
            &:hover {
              background: #e60012;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .about_m6 {
    width: 100%;
    overflow: hidden;
    margin-top: 80px;
    .m {
     // background: url(../assets/i33.jpg) no-repeat left center;
      display: flex;
      padding: 50px 0px;
      width: 1200px;
      height: auto;
      margin: 0px auto;
      .l {
        flex: 1;
      }
      .ms {
        height: auto;
        ul {
          width: 590px;
          overflow: hidden;
          float: right;
          li {
            width: 420px;
            height: 115px;
            border: 1px solid #e5e5e5;
            border-radius: 5px;
            padding: 30px 40px;
            display: flex;
            margin: 0px auto 20px;
            transition: 0.5s;
            .r {
              flex: 1;
              margin-left: 40px;
              overflow: hidden;
              .p1 {
                overflow: hidden;
                font-size: 16px;
                color: #333;
                height: 20px;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: bold;
              }
              .p2 {
                overflow: hidden;
                font-size: 15px;
                color: #999;
                height: 20px;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: 5px;
                line-height: 20px;
                transition: 0.5s;
              }
            }

            .i {
              width: 50px;
              height: 50px;
              transition: 0.5s;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          > :hover {
            width: 480px;
            height: 150px;
            box-shadow: 0px 0px 15px #ddd;
            border-color: #fff;
          }
        }
      }
      .p1 {
        font-size: 40px;
        color: #333;
      }
      .p2 {
        font-size: 18px;
        color: #191919;
        line-height: 40px;
        margin-top: 25px;
        margin-bottom: 25px;
        margin-top: 10px;
      }
      .p3 {
        overflow: hidden;
        margin-top: 20px;
        margin-bottom: 50px;
        a {
          display: inline-block;
          background: #fff;
          border: 1px solid #7d7d7d;
          font-size: 16px;
          color: #000;
          width: 140px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          border-radius: 10px;
          &:hover {
            background: #e60012;
            color: #fff;
          }
        }
      }
    }
  }
  .about_m5 {
    width: 100%;
    background-image: url(../assets/d39ec94866a15c80108f7b6f0d1058f.png);
    height: 589px;
    background-repeat: no-repeat;
    background-position: center center;
    .m {
      width: 1200px;
      height: auto;
      margin: 0px auto;
      display: flex;
      p {
        flex: 1;
        align-self: center;
        font-size: 24px;
        color: #000;
        line-height: 44px;
        padding-top: 220px;
      }
    }
  }
}
</style> 