<template>
  <div class="indexs">
    <div class="index_box animated fadeInUp">
      <div class="parallax-bg"></div>
      <div class="box_text">
        <h1 class="animated fadeInRight" style="font-weight: bold; color: #000">
          大凡 竞无止境
        </h1>
        <p class="animated fadeInUp" style="margin-top: 37px">
          专注量化CTA策略，使用机器学习处理海量数据、回归分析建立交易模型、分析期货市场走势，连续多年取得稳健收益
        </p>
      </div>
    </div>

    <div class="index_news">
      <div class="news_content">
        <div class="v1">
          <span><i class="icon iconfont icon-gonggaoliebiao"></i></span
          >分红公告：<a style="line-height: 50px;
    font-size: 16px;
    color: #333;" href="javascript:void(0)" @click="routerFun('/goodsteam')">
							                      		大凡1号 2021年10月分红</a>
        </div>
        <div class="v2">
          <el-row :gutter="0">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div
                style="
                  position: relative;
                  box-sizing: border-box;
                  padding: 0px 20px;
                "
              >
                <div class="bg">
                  <img src="../assets/i2.jpg" alt="" />
                </div>
                <div class="bg_text">
                  <p class="p1">基金产品</p>
                  <p class="p2">
                    为 <span>5000+</span> 高净值客户提供可靠的<br />资产管理服务
                  </p>
                  <p class="p3"><a @click="routerFun('/goodsteam')" href="javascript:void(0)">了解产品</a></p>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div
                style="
                  width: 100%;
                  height: 375px;
                  position: relative;
              
                  box-sizing: border-box;
                  padding: 20px 20px 20px 20px;
                "
              >
                <div class="bg">
                  <img src="../assets/i3s.jpg" alt="" />
                </div>
                <div class="bg_text">
                  <p class="p1" style="    color: white;">关于我们</p>
                  <p class="p2" style="    color: white;">
                    信号识别、机器学习领域专家团队
                  </p>
                  <p class="p3"><a   @click="routerFun('/goodsteam')" href="javascript:void(0)">了解产品</a></p>
                </div>
              </div>
              <div
                style="
                  width: 100%;
                  height: 375px;
                  position: relative;
                  box-sizing: border-box;
                  padding: 0px 20px 20px 20px;
                "
              >
              <div class="bg">
                  <img src="../assets/i4s.jpg" alt="" />
                </div>
                <div class="bg_text">
                  <p class="p1">公司新闻</p>
                  <p class="p2">
                    用实业思维做投资，在认知范围之内做选择
                  </p>
                  <p class="p3"><a href="javascript:void(0)" @click="routerFun('/about')">了解产品</a></p>

                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@media (max-width: 975px) {
  .index_news {
    height: auto  !important;
    width: auto !important;
    overflow: hidden;
    .news_content {
      width: auto !important;
      .v1{
        padding-left: 20px;
      }
    }
  }
}
.news_content {
  width: 1200px;
  height: 100%;
  margin: 0 auto;

  .v2 {
    width: auto;
    height: 100%;
    overflow: hidden;
    .news_about {
    }
    .news_box {
      width: 100%;
      height: 50%;
      box-sizing: border-box;
      padding: 0px 20px 0px 20px;
    }
    .bg_text {
      width: auto;
      padding: 45px 0px 0px 65px;
      position: absolute;
      left: 0px;
      top: 0px;
      .p1 {
        color: #000;
        font-weight: bold;
        font-size: 36px;
      }
      .p2 {
        color: #000;
        font-size: 18px;
        line-height: 36px;
        margin-top: 30px;
        span {
          font-size: 22px;
          font-weight: bold;
        }
      }
      .p3 {
        margin-top: 80px;
        a {
          display: inline-block;
          background: #fff;
          border: 1px solid #7d7d7d;
          font-size: 16px;
          color: #000;
          width: 140px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          border-radius: 10px;
          &:hover {
            background: #e60012;
            color: #fff;
          }
        }
      }
    }
    .bg {
      width: 100%;
      height: 100%;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      img {
        display: block;
        outline: none;
        border: 0;
        text-decoration: none;
        height: 100%;
        width: 100%;
        transition: 1.5s;

        z-index: -1;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .v1 {
    width: auto;

    line-height: 50px;
    color: #e60012;
    font-size: 16px;
    font-weight: bold;
    padding: 20px 0px;
    span {
      display: inline-block;
      height: 100%;
      line-height: 50px;
      float: left;
    }
    i {
      font-size: 40px;
      margin-right: 5px;
      position: relative;
      color: #e60012;
    }
  }
}
</style>
  	<style>
.index_news {
  width: 100%;
  height: 888px;
  background: #fafafa;
}
.indexs {
  position: relative;
}
.index_box {
  height: 650px;
  margin: 0 auto;
  min-height: 650px;
  overflow: hidden;
}
.box_text {
  z-index: 99;
  width: 1200px;
  color: black;
  position: relative;
  margin: 210px auto 0px auto;
  padding-left: 10px;
  box-sizing: border-box;
  z-index: 999;
}
.parallax-bg {
  width: 100%;
  height: 650px;
  background-image: url("../assets/i1.jpg");
  transition: all 3s;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 80;

  background-size: cover;
  transform: translate3d(0%, 0px, 0px);
  background-position: center;
}
@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(1); /*开始为原始大小*/
  }
  25% {
    transform: scale(1.2); /*放大1.2倍*/
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
}
.parallax-bg {
  animation: scaleDraw 30s ease-in-out 1;
}
</style>
  <script>
import "animate.css";
import HelloWorld from "../components/HelloWorld.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    HelloWorld,
  },

  name: "index",
  data() {
    return {};
  },
  mounted() {
    // document.documentElement.scrollTop = 0;
  },
  watch: {
    token: {
      handler: function (newval, oldval) {
        if (newval =='') {
           // this.delCookie()
        }
      },
    },
  },
  computed: {
    ...mapGetters([ "token", "is_active"]),
  },
  destroyed() {},
  methods: {

    routerFun(url) {
        this.$router.push(url)
    },
  },
};
</script>
  <style >
</style> 