<template>
  <div
    class="scroll-content bbox"
    @scroll="onScroll"
    :style="
      'overflow-x: hidden; margin-top:-40px; overflow-y: auto; height:' +
      contentStyleObj.height
    "
  >
    <div v-if="is_active == 3" class="scroll-item">
      <div class="box">
        <div
          class="box-nav"
          style="
            text-align: left;

            position: relative;
          "
        >
          <h2 style="line-height: 40px">{{ fund_data.fund_name }}</h2>
          <!-- <img
            src="../assets/a29kj-p8ab5.png"
            alt=""
            style="position: absolute; top: 0px; right: 0px"
          /> -->
          <el-tag type="success" size="mini">私募</el-tag>
          <el-tag
            type="info"
            size="mini"
            style="margin-left: 10px; margin-bottom: 10px"
            >多资产策略</el-tag
          >
          <el-tag
            type="warning"
            size="mini"
            style="margin-left: 10px; margin-bottom: 10px"
            >宏观策略</el-tag
          >
          <!-- <el-tag
            type="danger"
            size="mini"
            style="margin-left: 10px; margin-bottom: 10px"
          > :xs="8" :sm="6" :md="4" :lg="3" 
            R5-高风险</el-tag
          > -->
        </div>
        <div class="box-title" style="margin: 14px 0px">
          <el-row :gutter="0">
            <el-col :xs="24" :sm="8"
              ><div class="box-title-text">
                累计净值：
                <span>
                  {{ fund_data.cumulative_net_worth }}
                </span>
                ({{ fund_data.update_date }})
              </div></el-col
            >
            <el-col class="box-title-tow" :xs="24" :sm="8"
              ><div class="box-title-text">
                今年来收益
                <el-tooltip placement="top">
                  <div slot="content">
                    <p>
                      区间收益率是指在既定区间内，基金实现的净值绝对收益，即累计净值增长率（采用分红再投资方式的单位累计净值）。
                    </p>
                    <p>计算公式如下：</p>
                    <p>
                      区间收益率=（区间末期累计净值-区间初期累计净值）/区间初期累计净值
                    </p>
                    <p>
                      注：区间包括：近一个月、近三个月、近半年、近一年、近两年、近三年、近五年、近十年、成立以来、今年以来。"
                    </p>
                  </div>
                  <i
                    class="el-icon-question"
                    style="cursor: pointer; color: #8c8c8c"
                  ></i>
                </el-tooltip>

                <span class="box-title-text-span">
                  {{ fund_data.this_yearfit_CNt }}%
                </span>
                (截止至{{ fund_data.update_date }})
              </div></el-col
            >
            <el-col :xs="24" :sm="8"
              ><div class="box-title-text">
                成立来收益
                <el-tooltip placement="top">
                  <div slot="content">
                    <p>
                      区间收益率是指在既定区间内，基金实现的净值绝对收益，即累计净值增长率（采用分红再投资方式的单位累计净值）。
                    </p>
                    <p>计算公式如下：</p>
                    <p>
                      区间收益率=（区间末期累计净值-区间初期累计净值）/区间初期累计净值
                    </p>
                    <p>
                      注：区间包括：近一个月、近三个月、近半年、近一年、近两年、近三年、近五年、近十年、成立以来、今年以来。"
                    </p>
                  </div>
                  <i
                    class="el-icon-question"
                    style="cursor: pointer; color: #8c8c8c"
                  ></i>
                </el-tooltip>
                <span class="box-title-text-span">
                  {{ fund_data.fit_CNt }}%
                </span>
                ({{ fund_data.update_date }})
              </div></el-col
            >
          </el-row>
        </div>
        <div class="box-li">
          <el-row :gutter="20" hidden-xs-onl>
            <el-col :xs="12" :sm="6">
              <div class="box-li-text">
                <div>
                  单位净值:
                  <el-tooltip placement="top">
                    <div slot="content">
                      <p>全站默认展示的累计净值都为分红再投资计算的累计净值</p>
                      <p>
                        即采用分红再投资方式对基金净值进行复权计算得出的净值
                      </p>
                      <p>（同时考虑拆分情况）</p>
                    </div>
                    <i
                      class="el-icon-question"
                      style="cursor: pointer; color: #8c8c8c"
                    ></i>
                  </el-tooltip>
                  <span
                    class="box-li-text-span"
                    style="font-size: 18px; font-weight: 600"
                    >{{ fund_data.unit_net_worth }}</span
                  >
                </div>
                <div>
                  基金状态 <span class="box-li-text-span">正在运作</span>
                </div>
                <div>
                  备案编号：
                  <span class="box-li-text-span">{{
                    RatesElements_Data.record_id
                  }}</span>
                </div>
              </div>
            </el-col>

            <el-col :xs="12" :sm="6">
              <div class="box-li-text">
                <div>
                  成立以来年化：
                  <el-tooltip placement="top">
                    <div slot="content">
                      <p>
                        定义：年化收益率是当前收益率转化为年度收益率，是过往业绩的理论年度收益率
                      </p>
                      <p>
                        备注：根据选定频率，查找开始日与截止日净值之间间隔的自然日区间天数。
                      </p>
                    </div>
                    <i
                      class="el-icon-question"
                      style="cursor: pointer; color: #8c8c8c"
                    ></i>
                  </el-tooltip>
                  <span class="box-li-text-span" style="color: #e1322d">
                    {{ fund_data.all_annualized_income_CNt }} %</span
                  >
                </div>
                <div>
                  成立日期
                  <span class="box-li-text-span">{{
                    fund_data.net_start_date
                  }}</span>
                </div>
                <div>
                  公司管理规模
                  <span class="box-li-text-span">{{
                    companydetails_data.management_scale
                  }}</span>
                </div>
              </div>
            </el-col>

            <el-col :xs="{ span: 12 }" :sm="6">
              <div class="box-li-text">
                <div>
                  成立以来回撤：
                  <span class="box-li-text-span"
                    >{{ fund_data.cumulative_maximum_drawdown_CNt }}%</span
                  >
                </div>
                <div>
                  管理人：
                  <span class="box-li-text-span" style="font-size: 11px;">{{
                  RatesElements_Data.company
                  }}</span>
                </div>
                <div>
                  近一年收益：
                  <span class="box-li-text-span" style="color: #e1322d"
                    >{{ fund_data.past_yearfit_CNt }}%</span
                  >
                </div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="6">
              <div class="box-li-text">
                <div>
                  成立以来夏普：
                  <span class="box-li-text-span">{{
                    fund_data.sharp_CNt
                  }}</span>
                </div>
                <div>
                  基金经理：
                  <span class="box-li-text-span">{{
                    RatesElements_Data.manager
                  }}</span>
                </div>
                <!-- <div>
                  近一年收益排名:
                  <span class="box-li-text-span"
                    ><span style="color: #e1322d">12</span>/70</span
                  >
                </div> -->
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div style="clear: both"></div>
      <div v-show="boxshow" class="boxshow">
        <div
          style="
            padding-left: 28px;
            width: 1200px;
            margin: 0 auto;
            position: relative;
          "
        >
          <h3 style="margin: 16px 0px">{{ fund_data.fund_name }}</h3>
          <!-- <img
            style="position: absolute; right: 0px; top: -4px"
            src="../assets/a29kj-p8ab5.png"
            alt=""
          /> -->
        </div>
        <el-tabs
          class="customer-tab2"
          style="width: 1200px; margin: 0 auto"
          @tab-click="jump"
          v-model="tabName"
        >
          <el-tab-pane
            v-for="(tab, index) in tabs"
            :name="tab.refName"
            :key="index"
            :label="tab.name"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div style="clear: both"></div>
      <el-tabs class="customer-tab" @tab-click="jump" v-model="tabName">
        <el-tab-pane
          v-for="(tab, index) in tabs"
          :name="tab.refName"
          :key="index"
          :label="tab.name"
        ></el-tab-pane>
      </el-tabs>
      <div style="clear: both"></div>
      <div>
        <!-- 收益走势图 -->
        <div :ref="tabs[0].refName" class="scroll-item">
          <!-- <div class="line-name">
            <h2>{{ tabs[0].name }}</h2>
          </div> -->
          <div class="sy_box">
            <el-tabs class="customer-tab3">
              <el-tab-pane label="收益走势图">
                <div class="sy_box2">
                  <EchartsChart
                    @tabClick-Date="receiveFromChild"
                    :fund_data="fund_data"
                    :chartData="chartData_m1"
                  />
                </div>
              </el-tab-pane>
              <el-tab-pane label="历史净值/分红">
                <EchartsChart_table :chartData="chartData_m1" />
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <!-- 收益风险指标 -->
        <!-- <div :ref="tabs[1].refName" class="scroll-item">
          <div
            style="
              width: 1210px;
              margin: 0 auto;
              box-sizing: border-box;
              padding: 10px 0px;
              background: white;
            "
          >
            <ReturnRiskindicators />
          </div>
        </div> -->
        <!-- 区间收益 -->
        <div :ref="tabs[1].refName" class="scroll-item">
          <div class="qx_box">
            <IntervalReturns
              :fund_data="fund_data"
              :chartData_m1="chartData_m1_moeney"
              :chartData="chartData_m2"
            />
          </div>
        </div>
        <!-- 费率&要素 -->
        <div :ref="tabs[2].refName" class="scroll-item">
          <div class="fl_box">
            <RatesElements
              :fund_data="fund_data"
              :chartData="RatesElements_Data"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-else style="padding-top: 231px">
      <el-empty description="暂无权限查看！"></el-empty>
    </div>
  </div>
</template>
  <script>
import moment from "moment";
import EchartsChart from "../components/tab/echarts.vue";
import EchartsChart_table from "../components/tab/EchartsChart_table.vue";
import ReturnRiskindicators from "../components/tab/ReturnRiskindicators.vue";
import IntervalReturns from "../components/tab/IntervalReturns.vue";
import RatesElements from "../components/tab/RatesElements.vue";
import { mapGetters } from "vuex";
export default {
  name: "charts",
  //props:['backgroundColor'],
  props: ["userName"],
  components: {
    EchartsChart,
    EchartsChart_table,
    ReturnRiskindicators,
    IntervalReturns,
    RatesElements,
  },
  computed: {
    ...mapGetters(["UserId", "token", "is_active"]),
  },
  data() {
    return {
      RatesElements_Data: {},
      fund_data: {
        fund_name: "",
        unit_net_worth: "",
        update_date: "",
        annualized_income_CNt: "",
        fund_create_date: "",
        CNt: "",
        record_number: "",
        annualized_income: "",
        fund_create_date: "",
        cumulative_maximum_drawdown: "",
        annualized_income_CNt: "",
        sharp: "",
        manager: "",
      },
      chartData_m1: [],
      chartData_m2: {},
      chartData_m1_moeney: [],
      companydetails_data: {
        core_people_list: [
          {
            name: "data",
          },
        ],
      },
      boxshow: false,
      tabIndex: "0",
      contentStyleObj: {
        height: "100px",
      },
      tabName: "setOneRef",
      tabs: [
        {
          name: "收益走势图",
          refName: "setOneRef",
        },
        // {
        //   name: "收益风险指标",
        //   refName: "setTwoRef",
        // },
        {
          name: "区间收益",
          refName: "setThreeRef",
        },
        {
          name: "费率&要素",
          refName: "setFourRef",
        },
      ],
    };
  },
  watch: {},
  created() {
    this.getHight();
    this.fund_data = this.$route.query;

    window.addEventListener("resize", this.getHight);
  },
  mounted() {
    if (this.is_active == 3) {
      this.ajax();
      this.weekly_data_ajax();
      this.companydetails_ajax();
      this.desp_list();
      this.echartsAjax_m1(
        this.getLastOneMonthRange()[0].format("YYYY-MM-DD"),
        this.getLastOneMonthRange()[1].format("YYYY-MM-DD")
      );
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.getHight);
  },
  methods: {
    desp_list() {
      this.$store
        .dispatch("user/productdetails", {
          product_id: this.fund_data.product,
        })
        .then((e) => {
          let objs = e;
          if (e) {
            console.log(e)
            objs.subscription_date = this.srt_fn(e.subscription_date);
            objs.redemption_date = this.srt_fn(e.redemption_date);
            objs.manage_rate = this.srt_fn(e.manage_rate);
            objs.trustee_fee = this.srt_fn(e.trustee_fee);
            objs.operation_service_fee = this.srt_fn(e.operation_service_fee);
            objs.sales_service_fee = this.srt_fn(e.sales_service_fee);
            objs.reward_rate = this.srt_fn(e.reward_rate);
            // objs.subscription_date = this.srt_fn(e.subscription_date)
            this.RatesElements_Data = e;
          }
        });
    },
    srt_fn(strs) {
      let str = JSON.stringify(strs);
      return (str = str.split("/n").join("<br/>"));
    },
    companydetails_ajax() {
      this.$store
        .dispatch("user/companydetails", {
          company_id: this.fund_data.company,
        })
        .then((e) => {
          this.companydetails_data = e;
        });
    },
    weekly_data_ajax() {
      this.$store
        .dispatch("user/weekly_data", {
          fund_list: [this.fund_data.fund_id],
          benchmark: "",
        })
        .then((e) => {
          this.chartData_m2 = e[0].monthmove;
        })
        .catch(() => {});
      this.$store
        .dispatch("user/fund_data", {
          display: "day",
          start_date: "",
          end_date: "",
          names: [this.fund_data.fund_name],
        })
        .then((e) => {
          let objs = e[this.fund_data.fund_name];
          let arrs = [];
          for (const key in objs) {
            objs[key]["dates"] = key;
            arrs.push(objs[key]);
          }

          this.chartData_m1_moeney = arrs;
        })
        .catch(() => {});
    },
    receiveFromChild(el) {
      this.echartsAjax_m1(el.start_date, el.end_date);
    },
    getLastOneMonthRange() {
      const start = moment().startOf("month").subtract(1, "months"); // 上个月的同一天，作为开始日期
      const end = moment().endOf("month"); // 当前月的最后一天，作为结束日期
      return [start, end];
    },
    echartsAjax_m1_table(start_date, end_date) {
      this.$store
        .dispatch("user/fund_data", {
          display: "week",
          start_date: start_date,
          end_date: end_date,
          names: [this.fund_data.fund_name],
        })
        .then((e) => {
          // console.log(e);
          let objs = e[this.fund_data.fund_name];
          let arrs = [];
          for (const key in objs) {
            objs[key]["dates"] = key;
            arrs.push(objs[key]);
          }

          this.chartData_m1 = arrs;
        })
        .catch(() => {});
    },
    echartsAjax_m1(start_date, end_date) {
      this.$store
        .dispatch("user/fund_data", {
          display: "day",
          start_date: start_date,
          end_date: end_date,
          names: [this.fund_data.fund_name],
        })
        .then((e) => {
          // console.log(e);
          let objs = e[this.fund_data.fund_name];
          let arrs = [];
          for (const key in objs) {
            objs[key]["dates"] = key;
            arrs.push(objs[key]);
          }

          this.chartData_m1 = arrs;
        })
        .catch(() => {});
    },
    ajax() {
      this.$store
        .dispatch("user/fund_details", {
          fund_id: [1],
          start_date: "",
          end_date: "",
        })
        .then((e) => {})
        .catch(() => {});
    },
    // tab click
    jump(tab, event) {
      let target = document.querySelector(".scroll-content");
      let scrollItems = document.querySelectorAll(".scroll-item");
      // 判断滚动条是否滚动到底部
      if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
        this.tabIndex = tab.index.toString();
      }
      let totalY = scrollItems[tab.index].offsetTop - scrollItems[0].offsetTop; // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)

      let distance = document.querySelector(".scroll-content").scrollTop; // 滚动条距离滚动区域顶部的距离
      // let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)
      // 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次

      // 计算每一小段的距离
      let step = totalY / 50;
      if (totalY > distance) {
        smoothDown(document.querySelector(".scroll-content"));
      } else {
        let newTotal = distance - totalY;
        step = newTotal / 50;
        smoothUp(document.querySelector(".scroll-content"));
      }

      // 参数element为滚动区域
      function smoothDown(element) {
        if (distance < totalY) {
          distance += step;
          element.scrollTop = distance + 800;

          setTimeout(smoothDown.bind(this, element), 10);
        } else {
          element.scrollTop = totalY + 800;
        }
      }

      // 参数element为滚动区域
      function smoothUp(element) {
        if (distance > totalY) {
          distance -= step;
          element.scrollTop = distance + 800;
          setTimeout(smoothUp.bind(this, element), 10);
        } else {
          element.scrollTop = totalY + 800;
        }
      }
    },
    // 滚动条滚动
    onScroll(e) {
      let scrollItems = document.querySelectorAll(".scroll-item");
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离

        //boxshow
        let judge =
          e.target.scrollTop >=
          scrollItems[i].offsetTop - scrollItems[0].offsetTop + 800;
        if (judge) {
          this.tabIndex = i.toString();
          // 找对应的tab-name值
          if (this.tabs[this.tabIndex]) {
            this.tabName = this.tabs[this.tabIndex].refName;
            break;
          }
        }
        if (e.target.scrollTop > 220) {
          this.boxshow = true;
        } else {
          this.boxshow = false;
        }
      }
    },
    getHight() {
      this.contentStyleObj.height = window.innerHeight - 0 + "px";
      // console.log( this.contentStyleObj.height )
    },
  },
};
</script> 
   <style lang="scss" >
@media (max-width: 975px) {
  .bbox {
    .box {
      width: 100%;
      .box-nav {
        width: 100%;
      }
      .box-title {
        width: 100%;
        .box-title-tow {
          margin: 10px 0px;
        }
      }
      .box-li {
        width: 100%;
      }
    }
    .fl_box {
      width: 100%;
    }
    .sy_box {
      width: 100%;
    }
    .sy_box2 {
      width: 100%;
    }
    .qx_box {
      width: 100%;
    }
    .fl_box {
      width: 100%;
    }
  }
}
.customer-tab.el-tabs {
  margin: 8px 0px;
  box-sizing: border-box;
  padding: 6px 0px 6px 30px;
  background: white;
  width: 1210px;
  margin: 0 auto 10px auto;
  .el-tabs__header {
    margin: 0px;
    .el-tabs__nav-wrap {
      .el-tabs__nav-scroll {
        .el-tabs__nav {
          .el-tabs__active-bar {
            background-color: rgba(225, 50, 45);
            height: 4px;
          }
          .el-tabs__item {
            color: black;
            font-weight: 400;
            font-size: 16px;
            &:hover {
              color: rgba(225, 50, 45);
              cursor: pointer;
              opacity: 1;
            }
          }

          .el-tabs__content {
            overflow: visible;
          }
          .el-tabs__item.is-active {
            color: rgba(225, 50, 45);
          }
        }
      }
    }
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
}
.customer-tab2.el-tabs {
  margin: 4px 0px;
  box-sizing: border-box;
  padding: 0px 0px 6px 30px;

  .el-tabs__header {
    margin: 0px;
    .el-tabs__nav-wrap {
      .el-tabs__nav-scroll {
        .el-tabs__nav {
          .el-tabs__active-bar {
            background-color: rgba(225, 50, 45);
            height: 4px;
          }
          .el-tabs__item {
            color: black;
            font-weight: 400;
            font-size: 16px;
            &:hover {
              color: rgba(225, 50, 45);
              cursor: pointer;
              opacity: 1;
            }
          }

          .el-tabs__content {
            overflow: visible;
          }
          .el-tabs__item.is-active {
            color: rgba(225, 50, 45);
          }
        }
      }
    }
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
}
.customer-tab3.el-tabs {
  margin: 3px 0px;
  box-sizing: border-box;
  padding: 0px 0px 6px 30px;

  .el-tabs__header {
    margin: 0px;
    .el-tabs__nav-wrap {
      .el-tabs__nav-scroll {
        .el-tabs__nav {
          .el-tabs__active-bar {
            display: none;
          }
          .el-tabs__item {
            color: #0009;
            font-weight: 600;
            font-size: 16px;
            &:hover {
              color: rgba(225, 50, 45);
              cursor: pointer;
              opacity: 1;
            }
          }

          .el-tabs__content {
            overflow: visible;
          }
          .el-tabs__item.is-active {
            color: black;
          }
        }
      }
    }
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
}
</style>
  
  
   <style >
.bbox {
  margin: 0;
  padding: 0;
  background: #f7f8fc;
}
.box-nav {
  width: 100%;
  margin: 0 auto;
}
.sy_box {
  width: 1210px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px 0px;
  background: white;
}
.sy_box2 {
  width: 1210px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px 0px;
  background: white;
}
.qx_box {
  width: 1210px;
  margin: 10px auto;
  box-sizing: border-box;
  padding: 10px 0px;
  background: white;
}
.fl_box {
  width: 1210px;
  margin: 10px auto;
  box-sizing: border-box;
  padding: 0px 0px;
  background: white;
}
.box-title {
  width: 100%;
  padding-bottom: 10px;
  margin: 0 auto !important;
}
.box-li {
  width: 100%;
  margin: 0 auto;
  font-weight: 600;
}
/*.box-title{
  width: 1200px;
            margin: 0 auto;
}
.box-title{
  width: 1200px;
            margin: 0 auto;
}
.box-title{
  width: 1200px;
            margin: 0 auto;
} */
.box {
  box-sizing: border-box;
  margin: 120px auto 10px auto;
  padding: 20px 20px;
  width: 1210px;
  /* margin: 0 auto; */

  background: white;
}
.boxshow {
  position: fixed;
  z-index: 6;
  background: white;
  width: 100vw;
  top: 90px;
  text-align: left;
}
.boxshow::after {
  display: block;
  height: 1px;
  width: 100%;
  content: "";
  position: absolute;
  bottom: 0px;
  background: linear-gradient(180deg, #dbdada, #eee);
}
.boxshow::before {
  display: block;
  height: 1px;
  width: 100%;
  content: "";
  position: absolute;
  top: 0px;
  background: linear-gradient(180deg, #dbdada, #eee);
}
.box-title-text {
  color: #000c;
  font-size: 14px;
  text-align: left;
  line-height: 30px;
}
.box-title-text span {
  font-size: 26px;
  font-weight: 600;
}

.box-title-text-span {
  color: #e1322d;
  font-size: 26px;
  font-weight: 600;
}

.box-li-text {
  font-size: 14px;
  text-align: left;
  line-height: 35px;
  position: relative;
}
.box-li-text::before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  right: -10px;
  background: #0000001a;
}
.box-li-text-span {
  display: inline-block;
  float: right;
}
</style> 