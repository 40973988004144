import axios from 'axios'
import {
  Message,
  Notification
} from 'element-ui'
import store from '@/store'
import {
  getToken
} from '@/utils/auth'
import router from '../router'

// create an axios instance
const service = axios.create({
  baseURL: '/api',
  timeout: 60000
})

// request interceptor
service.interceptors.request.use(
  config => {
    //if (store.getters.token) {
    config.headers['Authorization'] = 'JWT ' + store.getters.token


    //}
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(

  response => {

    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (response.status == 201) {
      //   console.log(response)
      //   store.dispatch('user/resetToken').then(() => {
      //     Message({
      //       message:res.message,
      //       type: 'error',
      //       duration: 3000
      //     })
      //     router.push('/login')
      //   })
      return res
    }
    if (response.status == 200) {
      //   console.log(response)
      //   store.dispatch('user/resetToken').then(() => {
      //     Message({
      //       message:res.message,
      //       type: 'error',
      //       duration: 3000
      //     })
      //     router.push('/login')
      //   })
      return res
    }

    if (res.code == 400) {

      Notification.error({
        title: '错误提示',
        message: res.msg,
        offset: 48,
        duration: 4500
      });
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

      return Promise.reject(new Error(res.msg || 'Error'))
    }
    if (res.code != 200 && res.code != 400 && res.code != 401 && response.status != 201 && res.status != 200) {
      // console.log(res)
      Message({
        message: res.data,
        type: 'error',
        duration: 5 * 1000
      })
      // Notify({
      //   title: '提示',
      //   message: '这是一条不会自动关闭的消息',
      //   duration: 0
      // });
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

      return Promise.reject(new Error(res.data || 'Error'))
    } else {

      //  console.log(111)
      return res
    }
    // else{
    //   return response
    // }
  },
  error => {

    console.log(error)
    if (error.response.status == 401) {
 
      store.dispatch('user/LogOut').then(() => {
        sessionStorage.clear();
        // location.reload();
        setTimeout(() => {
          Message({
            message: 'token失效，请重新登录',
            type: 'warning',
            duration: 5 * 1000
          })
          window.location.reload();
        }, 100);
      })
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    }

    return Promise.reject(error)
  }
)

export default service