import {
  login,
  fund_corrcoef,
  fund_data,
  fund_datanew,
  fund_details,
  fund_list,
  fund_raw,
  get_news,
  edit_user,
  user_info,
  productdetails,
  asset_file,
  weekly_data,
  create_user,
  companydetails,
  send_msg,
  query_user
} from '@/api/echarts'
import {
  getToken,
  setToken,
  removeToken,
  get_is_active,
  set_is_active,
  remove_is_active,
} from '@/utils/auth'

const getDefaultState = () => {
  return {
    token: '',
    name: '',
    avatar: '',
    UserId: '',
    group_type: '',
    is_active: 0,
    roleCode: [1, 2, 3, 4],
    addRouters: [],
  }
}
const state = getDefaultState()
const mutations = {


  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },

  USER_SETid: (state, UserId) => {
    state.UserId = UserId
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_ACTIVE: (state, active) => {
    state.is_active = active
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_group_type: (state, group_type) => {
    state.group_type = group_type
  },

}

const actions = {

  get_news({
    commit,
    state
  }, data) {
    return new Promise((resolve, reject) => {
      get_news(data).then(response => {

        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  edit_user({
    commit,
    state
  }, data) {
    return new Promise((resolve, reject) => {
      removeToken();
      remove_is_active()

      edit_user(data).then(response => {

        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  user_info({
    commit,
    state
  }, data) {
    return new Promise((resolve, reject) => {
      user_info(data).then(response => {
        commit('SET_ACTIVE', '')

        commit('SET_NAME', response.data.username)
        commit('SET_group_type', response.data.group_type)

        commit('SET_ACTIVE', response.data.evaluate)

        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  productdetails({
    commit,
    state
  }, data) {
    return new Promise((resolve, reject) => {
      productdetails(data).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  asset_file({
    commit,
    state
  }, data) {
    return new Promise((resolve, reject) => {
      asset_file(data).then(response => {
        // const {
        //   data
        // } = response
        //commit('USER_order_data', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  create_user({
    commit,
    state
  }, data) {
    return new Promise((resolve, reject) => {
      create_user(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  fund_list({
    commit,
    state
  }, data) {
    return new Promise((resolve, reject) => {
      fund_list(data).then(response => {
        // const {
        //   data
        // } = response
        //commit('USER_order_data', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  companydetails({
    commit,
    state
  }, data) {
    return new Promise((resolve, reject) => {
      companydetails(data).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },


  send_msg({
    commit,
    state
  }, data) {
    return new Promise((resolve, reject) => {
      send_msg(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  query_user({
    commit,
    state
  }, data) {
    return new Promise((resolve, reject) => {
      query_user(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },


  fund_details({
    commit,
    state
  }, data) {
    return new Promise((resolve, reject) => {
      fund_details(data).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },


  fund_data({
    commit,
    state
  }, data) {
    return new Promise((resolve, reject) => {
      fund_data(data).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },


  weekly_data({
    commit,
    state
  }, data) {
    return new Promise((resolve, reject) => {
      weekly_data(data).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  loginPost({
    commit,
    state
  }, data) {

    return new Promise((resolve, reject) => {

      commit('SET_ACTIVE', '')
      commit('USER_SETid', data.user_id)
      commit('SET_NAME', data.user_name)
      commit('SET_group_type', data.group_type)
      commit('SET_TOKEN', data.token)
      commit('SET_ACTIVE', data.evaluate)
      setToken(data.token)
      //set_is_active(response.evaluate)
      resolve(data)

    })
  },
  login({
    commit,
    state
  }, data) {

    return new Promise((resolve, reject) => {
      removeToken();
      remove_is_active()

      login(data).then(response => {
 
        // commit('SET_ACTIVE', '')
        // commit('USER_SETid', response.user_id)
        // commit('SET_NAME', response.user_name)
        // commit('SET_group_type', response.group_type)
        // commit('SET_TOKEN', response.token)
        // commit('SET_ACTIVE', response.evaluate)
        // setToken(response.token)
        //set_is_active(response.evaluate)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  LogOut({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {


      commit('USER_SETid', '')
      commit('SET_NAME', '')
      commit('SET_group_type', '')
      commit('SET_TOKEN', '')
      commit('SET_ACTIVE', '')
      //remove_is_active()
      removeToken()
      resolve()

    })
  },











}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}